import { graphql, PageProps, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';

const Offer: React.FC<PageProps> = () => {
  const query = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "offer.webp" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `
  );
  return (
    <Layout>
      <SEO title="Offers" />
      <div style={{ width: `100%`, margin: `0 auto` }}>
        <Img
          style={{ display: `block`, margin: `0 auto` }}
          fluid={query.file.childImageSharp.fluid}
          alt="Offer"
        />
      </div>
    </Layout>
  );
};

export default Offer;
